import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  search: string = "";
  rows = [];
  selectedRows = [];
  loading:boolean = false;

  constructor(private invoiceService: InvoiceService, private snotifyService: SnotifyService) {

  }

  ngOnInit(): void {
    console.log("Loading Page");
    this.loadTable();
    console.log("Finished Page");
  }

  onSearch() {
    this.loadTable();
  }

  loadTable() {
    // if (this.search == "")
    //   return;
    this.loading = true;
    this.invoiceService.get(this.search).then((invoices) => {
      for (let invoice of invoices) {
        invoice.selected = false;
      }
      this.rows = invoices;
      console.log(this.rows);
      this.loading = false;
    }, (err) => {
      console.log(" - -----------------------------------------------------");
      console.log("Error");
      //this.snotifyService.error("a error occured");
      this.loading = false;
    });
  }

  invoiceAll() {
    this.invoice(this.rows);
  }

  invoiceSelected() {
    if (this.rows.filter(r => r.selected == true).length == 0) {
      alert("No items selected");
    } else {
      this.selectedRows = this.rows.filter(r => r.selected == true);
      this.invoice(this.selectedRows);
    }
  }

  invoice(invoices) {
    console.log(invoices);
    this.loading = true;
    this.invoiceService.issue(invoices).then((data) => {
      this.loadTable();
    });
  }
}
