// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

  //export const environment = {
  // production: false,
  // reportableUrl: 'http://reportable.dev5.corerfid.com/api/bhp/',
  // //apiUrl: 'http://localhost:5000/api/'
  //  apiUrl: 'http://bhpapi.dev5.corerfid.com/api/'
  //};

 export const environment = {
 production: true,
 reportableUrl: 'https://reportable.corerfid.net/api/bhp/',
 apiUrl: 'https://bhpapi.corerfid.net/api/'
 };
